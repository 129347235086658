import React, { useState, useEffect } from 'react';
import moment from 'moment';

const CountdownTimer = ({ targetDate, acao }) => {
  const [loading, setLoading] = useState(true);
  const [timeRemaining, setTimeRemaining] = useState('');
  const [isMessageSent, setMessageSent] = useState(false);

  // Função para verificar e ajustar o formato da data
  const parseTargetDate = (dateString) => {
    if (dateString.includes('T')) {
      return moment(dateString).utc(); // Converte para UTC
    }
    return moment(dateString, "YYYY-MM-DD HH:mm:ss").utc();
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = moment.utc(); // Garante que now esteja em UTC
      const endDate = parseTargetDate(targetDate); // targetDate em UTC
      const duration = moment.duration(endDate.diff(now));
  
      if (duration.asMilliseconds() <= 0) {
        clearInterval(intervalId);
        setTimeRemaining("Expirado");
        return;
      }
  
      const days = Math.floor(duration.asDays()); // Obtem o total de dias inteiros
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();
  
      setTimeRemaining(`${days} dias, ${hours} horas ${minutes} minutos e ${seconds} segundos`);
      setLoading(false);
  
      if (!isMessageSent && days <= 6) {
        acao(true);
        setMessageSent(true);
      } else if (isMessageSent && days > 6) {
        acao(false);
        setMessageSent(false);
      }
    }, 1000);
  
    return () => clearInterval(intervalId);
  }, [targetDate]);
  

  const expirationDate = moment(targetDate).format('DD/MM/YYYY');

  if (loading) {
    return <span className="loader"></span>;
  }

  if (moment().isAfter(targetDate)) {
    return (
      <div style={{ backgroundColor: 'rgba(255,0,0,0.5)', fontWeight: 'bold', color: 'white', padding: 5, marginBottom: 10, borderRadius: 5, alignItems: "center", display: 'flex', justifyContent: "center" }}>
        <p>Este plano está expirado em <strong>{expirationDate}</strong>.</p>
      </div>
    );
  } else if (timeRemaining.startsWith('0')) {
    const renewMessage = "Renove seu plano agora!";
    return (
      <div style={{ backgroundColor: 'rgba(255,165,0,0.8)', fontWeight: 'bold', color: 'white', padding: 5, marginBottom: 10, borderRadius: 5, alignItems: "center", display: 'flex', justifyContent: "center" }}>
        <p>Este plano expira hoje, em <strong>{expirationDate}</strong>. {renewMessage} <CountdownToRenew targetDate={targetDate} /></p>
      </div>
    );
  } else {
    return (
      <div style={{ backgroundColor: 'rgba(34,148,242,1)', fontWeight: 'bold', color: 'white', padding: 5, marginBottom: 10, borderRadius: 5, alignItems: "center", display: 'flex', justifyContent: "center" }}>
        <p>Tempo restante para vencer o plano <strong style={{ fontSize: 20 }}>{timeRemaining}</strong>. Este plano expira em <strong>{expirationDate}</strong>.</p>
      </div>
    );
  }
};

const CountdownToRenew = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const now = moment();
    const endDate = moment.utc(targetDate);
    return Math.max(endDate.diff(now) / 1000, 0);
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate]);

  const hours = Math.floor(timeLeft / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const seconds = Math.floor(timeLeft % 60);

  return (
    <strong>Renove em {hours} horas, {minutes} minutos e {seconds} segundos</strong>
  );
};

export default CountdownTimer;
